<template>
    <uw-content title="我的团队">

        <!-- 分类 -->
        <template slot="prefix">
            <el-link :underline="false" class="w-margin-r-10" v-for="(item, idx) in team" :key="idx" :type="teamSelect.id === item.id ? 'primary' : 'info'" @click="TeamSearchFirst(item.id)">
                <svg class="w-icon" aria-hidden="true" font-size="14px">
                    <use :xlink:href="item.icon"></use>
                </svg>
                {{ item.name }}
            </el-link>
        </template>

        <!-- 主体 -->
        <div class="uws-content">

            <!-- 数据窗口 -->
            <div class="uws-window">

                <!-- 内容 -->
                <uw-scrollbar class="uws-window-body">

                </uw-scrollbar>

                <!-- 页脚 -->
                <div class="uws-window-footer">
                    ----预留功能栏----
                </div>
            </div>

            <!-- 团队成员 -->
            <uw-scrollbar class="uws-team">
                <div class="uws-team-user" v-for="(item, idx) in teamSelect.user_members" :key="idx">
                    <div class="uws-team-user-avatar">
                        <el-avatar :size="36" :src="item.avatar">{{ item.name[0] }}</el-avatar>
                    </div>
                    <div class="uws-team-user-name">
                        <div :style="{ fontSize: '14px', color: item.sex == 0 ? '#ff9800' : (item.sex == 1 ? '#2196f3' : '#e91e63') }">
                            {{ item.sex == 0 ? '∮' : (item.sex == 1 ? '♀' : '♂') }}
                            <b>{{ item.name }}</b>
                        </div>
                        <div class="uws-team-user-name-motto">
                            {{ item.job_number }} : {{ item.motto ?? '这家伙很懒!' }}
                        </div>
                    </div>
                </div>
            </uw-scrollbar>

        </div>

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            value1: 5,
            team: [],
            teamSelect: {},
        }
    },

    mounted () {
        this.TeamSearchIn()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 相关团队
        |--------------------------------------------------
        |
        */
        
        TeamSearchIn() {
            this.$http.post('/9api/users-team/search-in').then(rps => {
                this.team = rps.data
                this.TeamSearchFirst(rps.data[0].id)
            })
        },

        TeamSearchFirst(id) {
            this.$http.post('/9api/users-team/search-first', { search: { id: id }, append: [ 'user_leader', 'user_members' ] }).then(rps => {
                this.teamSelect = rps.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .uws-content {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .uws-window {
            width: calc(100% - 265px);
            height: 100%;
            float: left;

            .uws-window-body {
                height: calc(100% - 80px);
                box-sizing: border-box;
                padding: 10px;
            }

            .uws-window-footer {
                height: 50px;
                margin: 10px 10px 20px 10px;
                border-radius: 5px;
                background-color: #ebedef;
                display: flex;
                align-items: center;
                color: #9e9e9e;
                justify-content: center;
            }
        }

        .uws-team {
            margin-left: 5px;
            padding: 10px;
            height: 100%;
            width: 260px;
            box-sizing: border-box;
            float: right;
            background-color: #f2f3f5;

            .uws-team-user {
                border-radius: 5px;
                cursor: pointer;
                padding: 5px;
                width: calc(100% - 10px);
                height: 36px;

                .uws-team-user-avatar {
                    height: 36px;
                    width: 36px;
                    float: left;
                }

                .uws-team-user-name {
                    width: calc(100% - 36px);
                    height: 36px;
                    float: left;
                    font-size: 12px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .uws-team-user-name-motto {
                        color: #9e9e9e;
                        font-size: 12px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                    }
                }

                &:hover {
                    background-color: #e0e0e0;
                }
            }
        }
    }
</style>